$font-family: "open-sans", sans-serif;

//primary colors
$Edison_Black: #101820;
$Edison_Green: #00664f;
$Edison_Yellow: #fed141;
$White: #fff;
$Edison_Gray: #707372;
$SubTextColor: #757575;
$LineColor: #979797;
$ButtonGreen: #588935;

//spacing
$spacing-sm: 1em;
$spacing-md: 2em;
$spacing-lg: 4em;

//border-radius
$borderRadius: 1.5em;

//headerHeight
$headerHeight: 6.25em;
$footerHeight: 3.5em;

//font
$fontFamily: "open sans", sans-serif;

@mixin breakpoint($point) {
  @if $point == md {
    @media (max-width: 1294px) {
      @content;
    }
  }
}
