/* ChatBot.module.css */
/* ChatBot.module.css */
@import '../styles/variables.scss';

.chatContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-Content: center;
    padding-inline: 3rem;
    padding-bottom: 20px;
    padding-top: 10px;
    box-Sizing: border-box;
    max-height: calc(100vh - 80px);
    /* total viewport height - navContainer's height - .spacer's height */
    overflow-y: auto;
    /* make it scrollable on the y-axis */
    height: calc(100vh - 80px);
    /* total viewport height - navContainer's height - .spacer's height */
    flex-direction: column;
}

.activityStatus {
    font-family: $fontFamily;
    opacity: 0.80;
    color: $SubTextColor;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;

    .activityStatus__sendStatus {
        font-size: 80%;
    }
}

.sendBoxButton {
    color: #FFF;
    font-family: "open sans", sans-serif;
    font-size: 17px;
    letter-spacing: -0.24px;
    line-height: 23px;
}

.loading {
    height: 64px;
    width: 64px;
    margin: auto;
}


@media (max-width: 480px){
    .chatContainer {
        max-height: calc(100vh - 60px);
        height: calc(100vh - 60px);
    }
  }

/* Styles specific to botframework-webchat might need to be overridden differently */