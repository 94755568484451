@import '../styles/variables.scss';

.header {
    color: $Edison_Black;
    font-family: $fontFamily;
    font-size: 32px;
    font-weight: 200;
    letter-spacing: -0.03px;
    line-height: 43px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

.navContainer {
    position: fixed;
    top: 0;
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Or an appropriate height for your design */
    background-color: $White;
    /* To ensure that it's not transparent and text from chat doesn't overlay */
    z-index: 1;
    /* Ensure it stays on top */
}

.titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding-top: 20px;
    /* Adjust as needed */
}

.rectangle {
    background-color: $Edison_Yellow;
    height: 8px;
    width: 104px;
    margin-top: 5px;
}

.bottomBorder {
    border: 1px solid $LineColor;
    box-sizing: border-box;
    height: 2px;
    margin-top: 10px;
    width: 100%;
    opacity: 0.38;
    /* Ensure the border spans the full width */
}

@media (max-width: 480px){
    .header {
        font-size: 18.5px;
        margin: 0px;
    }
    .titleContainer {
        padding-top: 0px;
    }
    .bottomBorder {
        display: none;
    }
  }