@import './variables.scss';

#askscebot {
  height: 100%;
}

.webchat__send-box__button {
  background-color: #257b68 !important;
  border-color: #257b68 !important;
}
